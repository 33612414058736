function MyUIHandler() {
  // This is intentional
}
 
MyUIHandler.prototype.constructor = MyUIHandler;
 
MyUIHandler.prototype.startActivityIndicator = function (actionContext, clientContext) {
  console.log("start activity indicator ");
};
 
MyUIHandler.prototype.endActivityIndicator = function (actionContext, clientContext) {
  console.log("end activity indicator");
};
 
MyUIHandler.prototype.controlFlowCancelled = function (clientContext) {
  console.log("control flow cancelled");
};
 
MyUIHandler.prototype.controlFlowStarting = function (clientContext) {
  console.log("control flow starting");
};
 
MyUIHandler.prototype.controlFlowEnded = function (error, clientContext) {
  console.log("control flow ended");
};
 
MyUIHandler.prototype.controlFlowActionStarting = function (
  actionContext,
  clientContextl
) {
  console.log("Control flow action starting");
};
 
MyUIHandler.prototype.controlFlowActionEnded = function (
  error,
  actionContext,
  clientContext
) {
  console.log("Control flow action starting");
};
 
MyUIHandler.prototype.handleAuthenticatorUnregistration = function (
  authenticatorDescription,
  isPlaceholder,
  actionContext,
  clientContext
) {
  console.log("handle authentication unregistration");
};
 
MyUIHandler.prototype.selectAuthenticator = function (
  options,
  actionContext,
  clientContext
) {
  console.log("select authenticator");
};
 
MyUIHandler.prototype.selectAuthenticatorFallbackAction = function (
  validOptions,
  fallbackAuthenticator,
  session,
  actionContext,
  clientContext
) {
  console.log("select authenticator fallback action");
};
 
MyUIHandler.prototype.controlOptionForCancellationRequestInSession = function (
  validOptions,
  session
) {
  console.log("control option for cancellation request in session");
};
 
MyUIHandler.prototype.createPasswordAuthSession = function (title, username) {
  console.log("create Password Auth Session");
};
 
MyUIHandler.prototype.createPinAuthSession = function (title, username, pinLength) {
  console.log("Pin Authenticator Session");
};
 
MyUIHandler.prototype.createPatternAuthSession = function (
  title,
  username,
  gridWidth,
  gridHeight
) {
  console.log("Pattern Authenticator Session");
};
 
MyUIHandler.prototype.createOtpAuthSession = function (
  title,
  username,
  possibleTargets,
  autoExecedTarget
) {
  console.log("Otp Authenticator Session");
};
 
MyUIHandler.prototype.createVoiceAuthSession = function (title, username) {
  console.log("Method not implemented.");
};
 
MyUIHandler.prototype.createSecurityQuestionAuthSession = function (title, username) {
  console.log("create Security Question Auth Session");
};
 
MyUIHandler.prototype.createPlaceholderAuthSession = function (
  placeholderName,
  placeholderType,
  title,
  username,
  authenticatorConfiguredData,
  serverPayload
) {
  console.log("Method not implemented by demo application.");
};
 
MyUIHandler.prototype.createFido2AuthSession = function (title, username) {
  console.log("create Fido2 Auth Session");
};
 
MyUIHandler.prototype.getConfirmationInput = function (
  title,
  text,
  continueText,
  cancelText,
  actionContext,
  clientContext
) {
  console.log("get Confirmation Input");
};
 
MyUIHandler.prototype.getInformationResponse = function (
  title,
  text,
  continueText,
  actionContext,
  clientContext
) {
  console.log("get Information Response");
};
 
MyUIHandler.prototype.createMobileApproveAuthSession = function (
  title,
  username,
  instructions
) {
  console.log("create Mobile Approve Auth Session");
};
 
MyUIHandler.prototype.createTicketWaitSession = function (actionContext, clientContext) {
  console.log("create Ticket Wait Session");
};
 
MyUIHandler.prototype.createTotpAuthSession = function (title, username) {
  console.log("create Totp Auth Session");
};
 
MyUIHandler.prototype.createFormSession = function (formId, payload) {
  console.log("create Form Session");
};
 
MyUIHandler.prototype.createAuthenticationConfigurationSession = function (userId) {
  console.log("create Authentication Configuration Session");
};
 
MyUIHandler.prototype.createRegistrationPromotionSession = function (
  userId,
  actionContext
) {
  console.log("create Registration Promotion Session");
};
 
MyUIHandler.prototype.processJsonData = function (
  jsonData,
  actionContext,
  clientContext
) {
  console.log("process Json Data");
};
 
MyUIHandler.prototype.handlePolicyRejection = function (
  title,
  text,
  buttonText,
  failureData,
  actionContext,
  clientContext
) {
  console.log("handle Policy Rejection");
  return Promise.resolve(com.ts.mobile.sdk.ConfirmationInput.create(-1));
};
 
MyUIHandler.prototype.handlePolicyRedirect = function (
  redirectType,
  policyId,
  userId,
  additionalParameters,
  clientContext
) {
  console.log("handle Policy Redirect");
  return Promise.resolve(com.ts.mobile.sdk.RedirectInput.create(com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy));
};
 
MyUIHandler.prototype.shouldIncludeDisabledAuthenticatorsInMenu = function (
  actionContext,
  clientContext
) {
  console.log("should Include Disabled Authenticators In Menu");
};
 
MyUIHandler.prototype.createScanQrSession = function (actionContext, clientContext) {
  console.log("create Scan Qr Session");
};
 
MyUIHandler.prototype.createFingerprintAuthSession = function (title, username) {
  console.log("create Fingerprint Auth Session");
};
 
MyUIHandler.prototype.createApprovalsSession = function (userId) {
  console.log("create Approvals Session");
};
 
MyUIHandler.prototype.createTotpGenerationSession = function (userId, generatorName) {
  console.log("create Totp Generation Session");
};
 
MyUIHandler.prototype.createDeviceManagementSession = function (userId) {
  console.log("create Device Management Session");
};
 
MyUIHandler.prototype.createNativeFaceAuthSession = function (title, username) {
  console.log("create Native Face Auth Session");
};
 
MyUIHandler.prototype.createFaceAuthSession = function (title, username) {
  console.log("create Face Auth Session");
};
 
MyUIHandler.prototype.createDeviceBiometricsAuthSession = function (title, username) {
  console.log("create Device Biometrics Auth Session");
};
 
MyUIHandler.prototype.localAuthenticatorInvalidated = function (description) {
  console.log("local Authenticator Invalidated");
};
 
MyUIHandler.prototype.setLogEnabled = function (isEnabled) {
  console.log("set Log Enabled");
};